/*
Color
	primary: #B4331C
	second: #6C83FE
*/

/*
** Global
**/

h1{
	font-size:2.60rem;
}

h2{
	font-size:2.28rem;
}

h3{
	font-size:1.96rem;
}

h4{
	font-size:1.64rem;
}

h5{
	font-size:1.32rem;
}

h6{
	font-size:1rem;
}

hr{
	margin: 0 auto;
	width: 85%;
	border-width: 1px 0 0;
}

.btn, .btn-large {
	background-color: #6C83FE;
}

.btn:hover, .btn-large:hover, button:focus {
	background-color: #899CFE;
}

.btn.btn-red{
	background-color: #B4331C;
}

.clear:after{
	content: '';
	clear: both !important;
}

#content{
	overflow: hidden;
}

i.rotate180{
	transform: rotate(180deg);
}

tfoot, tr.separator{
	border-top: 1px solid #D0D0D0;
}

ul.list {
	padding-left: 25px;
	list-style: disc;
}

ul.list li {
	list-style: disc;
}

/*
** Nav
**/

nav {
	background-color: #B4331C;
}

.logo img{
	width: 100%;
}

.bold > a {
	font-weight: bold;
}

ul.side-nav.fixed li a {
	font-size: 13px;
	line-height: 44px;
	height: 44px;
}

ul.side-nav.fixed li.logo {
	text-align: center;
	margin-top: 32px;
	height: 90px;
}

ul.side-nav.fixed li.separator {
	border-bottom: 1px solid #DDD;
}

#logo-container {
	margin-bottom: 32px;
	padding: 0;
}

ul.side-nav.fixed li:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

ul.side-nav.fixed li.active {
	background-color: #B4331C;
}

ul.side-nav.fixed li.active a {
	color: #FFF;
}

ul.side-nav.fixed li.logo:hover {
	background-color: inherit;
}

nav.top-nav a.page-title h1 {
	font-size: 48px;
	margin: 0;
	font-weight: 300;
}

nav.top-nav {
	height: 122px;
	box-shadow: none;
	overflow: hidden;
	display: flex;
	justify-content: center;
	flex-direction: column;
}


@media only screen and (min-width : 993px) {
	header, main, footer {
		padding-left: 240px;
	}
}

@media only screen and (max-width : 992px) {
	.nav-wrapper {
		text-align: center;
	}

	.top-nav .container {
		width: 100%;
		padding-left: 48px;
	}

	nav.top-nav a.page-title h1 {
		font-size: 36px;
	}
}

a.button-collapse.top-nav {
	position: absolute;
	text-align: center;
	height: 48px;
	width: 48px;
	left: 0;
	top: 0;
	float: none;
	margin-left: 1rem;
	color: #FFF;
	font-size: 32px;
	z-index: 2;
	line-height: 122px;
}

/*
** Footer
**/

body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

main {
	flex: 1 0 auto;
}

footer.page-footer{
	padding-top: 0;
}

footer.page-footer {
	background-color: #B4331C;
}

footer.page-footer .footer-copyright {
	line-height: 20px;
	height: auto;
	padding: 5px 0;
}

footer.page-footer .footer-copyright a {
	color: inherit;
	font-weight: bold;
}

/*
** Alert
**/

.alert {
	padding: 10px 20px;
	margin: 5px 0;
	font-weight: bold;
}

.alert i {
	vertical-align: -5px;
	font-size: 1.5rem;
}

.alert.alert-danger {
	background-color: #B4331C;
	color: #ffffff;
}

.alert.alert-warning {
	background-color: #ffa000;
	color: #ffffff;
}

.alert.alert-success {
	background-color: #2BBBAD;
	color: #ffffff;
}

.alert.alert-info {
	background-color: #6C83FE;
	color: #ffffff;
}

/*
** Tarif
**/

.promo{
	text-decoration: line-through;
	font-size: 0.7em;
}

/*
** Identités
**/

.identites-header-img {
	width: 90%;
	margin: 0 auto;
	display: block;
}

.identites{
	font-size: 0.9rem;
	text-align: center;
}

.identites .image{
	width: 75%;
	display: block;
	margin: 0 auto;
}

.identites .title{
	font-weight: bold;
	font-size:1.1rem;
	line-height:1.5rem;
}

/*
** Autres services
**/

.service{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0;
}

.service img.l{
	margin-right: 10px;
}

.service img.r{
	margin-left: 10px;
}

.service h4{
	font-weight: bold;
}

.service .text{
	flex: 1;
}

/*
** Show password
**/

.show-password{
	position: absolute;
	right: 0;
	top: 0.6em;
	cursor: pointer;
}

.show-password .visible{
	display: none;
}

.show-password.show .visible{
	display: block;
}

.show-password.show .not-visible{
	display: none;
}

input::-ms-clear, input::-ms-reveal{
	display:none;
}

/*
** Contact
**/

.contact{
	display: flex;
	flex-wrap: wrap;
}

.contact .text{
	white-space: nowrap;
	flex: 0 auto;
	margin-right: 10px;
}

.contact .map{
	display: flex;
	flex: 1;
	min-width: 300px;
}

.contact .map iframe{
	flex: 1;
	min-height: 100%;
}

/*
** Envoi photo
**/

.small {
	font-size: 0.8rem;
}

@media (min-width: 1600px) {
	.picture{
		width: calc(25% - 10px);
	}
}

@media (max-width: 1600px) {
	.picture{
		width: calc(33% - 10px);
	}
}

@media (max-width: 1200px) {
	.picture{
		width: calc(50% - 10px);
	}
}

@media (max-width: 600px) {
	.picture{
		width: calc(100% - 10px);
	}

	.picture-wrapper{
		flex-direction: column;
	}

	#page2 table{
		display: block;
		overflow: auto;
	}
}

.picture {
	margin-right: 5px;
	margin-left: 5px;
	min-height: 250px;
	position: relative;
	overflow: hidden;
}

.picture .picture-inner, .picture .deleting-mask {
	transition: 1s;
}

.picture .deleting-mask {
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	pointer-events: none;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, .75);
	opacity: 0;
}

.picture .deleting-mask i {
	font-size: 7.5em;
	animation: pulse 2s alternate infinite ease-in-out;
	color: transparent;

	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25) 50%, white 50%, white);
	background-size: 400% 400%;
	background-clip: text;
	-webkit-background-clip: text;
}

@keyframes pulse {
	from {
		background-position-y: 45.5%;
	}
	to {
		background-position-y: 48.5%;
	}
}

.picture:not(.loading, .deleting){
	cursor: pointer;
	overflow: visible;
}

.picture.no-cursor{
	cursor: auto;
}

.picture img{
	width: 100%;
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.picture .selector{
	position: absolute;
	transition: all 0.2s;
	left: 0;
	right: 0;
	top: 0;
	height: 0;
	background-color: #2BBBAD;
}

.picture.select .selector, .picture.select:hover .selector{
	height: 20px;
}

.picture.deleting:hover .selector{
	height: 0;
}

.picture.deleting .picture-inner {
	filter: blur(3px);
}

.picture.deleting .deleting-mask {
	opacity: 1;
	pointer-events: auto;
}

.picture > div{
	height: 100%;
}

.picture:hover .selector{
	height: 5px;
}

.picture .ratio{
	position: absolute;
	right: 0;
	top: 0;
	background-color: rgba(255, 255, 255, 0.5);
	padding: 0 5px;
}

.picture-wrapper{
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	justify-content: center;
}

.picture-wrapper.picture-wrapper-left{
	justify-content: left;
}

.picture-form {
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	width: 275px;
	pointer-events: none;
	transition: all 0.75s;
	transform: translateX(100%);
	z-index: 1;
}

.picture-form-show {
	transform: translateX(0%);
}

.picture-form form {
	padding: 25px;
	background-color: #FFF;
	pointer-events: auto;
}

#droparea{
	text-align: center;
	margin: 25px 0;
	padding: 20px;
	transition: all 0.25s;

	box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

#droparea.hover{
	box-shadow:0 12px 15px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.comment{
	margin: 25px 0;
	padding: 10px 20px;
	transition: all 0.25s;

	box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.comment label{
	font-size: 1rem;
	color: #B4331C;
}

#droparea .drop{
	color: #B4331C;
}

.picture .progress-bar{
	width: 100%;
	background-color: #2BBBAD;
	position: absolute;
	transition: height 0.1s linear;
}

.picture .progress-bar:after, .picture .progress-bar:before{
	position: absolute;
	right: 0;
	left: 0;
	text-align: center;
	font-size: 25px;
	cursor: default;
}

.picture .progress-bar:before{
	bottom: 0;
	color: white;
	content: attr(data-pc);
}

.picture .progress-bar:after{
	bottom: -35px;
	color: #2BBBAD;
	content: attr(data-pc);
}

.button-row{
	margin: 10px 0;
}

.button-row a{
	margin: 5px 0;
}

.pages{
	width: 400%;
	transition: all .75s;
}

.pages.page2{
	transform: translateX(-25%);
}
.pages.page3{
	transform: translateX(-50%);
}

.pages .page{
	transition: all .75s;
	float: left;
	width: 25%;
}

#first-page{
	opacity: 1;
	pointer-events: inherit;
}

#page2{
	opacity: 0;
	pointer-events: none;
}

#page3{
	opacity: 0;
	pointer-events: none;
}

.page2 #first-page{
	opacity: 0;
	pointer-events: none;
}

.page2 #page2{
	opacity: 1;
	pointer-events: inherit;
}

.page2 #page3{
	opacity: 0;
	pointer-events: none;
}

.page3 #first-page{
	opacity: 0;
	pointer-events: none;
}

.page3 #page2{
	opacity: 0;
	pointer-events: none;
}

.page3 #page3{
	opacity: 1;
	pointer-events: inherit;
}

@keyframes btn-loading {
	0%, 100%{
		transform: scaleY(1);
	}
	50%{
		transform: scaleY(1.25);
	}
}

.btn-partable{
	position: relative;
	overflow: visible;
}

.btn-part{
	display: inline-block;
	position: absolute;
	height: 100%;
	width: 20%;
	top: 0;
	background-color: inherit;
	z-index: -1;
}

.loading .btn-part{
	animation: btn-loading .75s ease-in-out infinite;
}

.btn-part.part1{
	left: 0;
	animation-delay: 0s;
}

.btn-part.part2{
	left: 20%;
	animation-delay: .075s;
}

.btn-part.part3{
	left: 40%;
	animation-delay: .15s;
}

.btn-part.part4{
	left: 60%;
	animation-delay: .225s;
}

.btn-part.part5{
	left: 80%;
	animation-delay: .3s;
}

.recap-table tfoot {
	background-color: #f2f2f2;
}

.recap-table td {
	padding-left: 10px;
	padding-right: 10px;
}
